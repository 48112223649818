@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.play-btn {
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  @keyframes b-zoom {
    0% {
      opacity: 0.5;
      @include transform(scale(1));
    }
    100% {
      @include transform(scale(1.5));
      opacity: 0;
    }
  }
  &.v1 {
    position: relative;
    width: 50px;
    height: 50px;
    font-size: 14px;
    color: $color-black;
    background: $color-yellow;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      opacity: 0.5;
      @include transform(scale(1.1));
      background: $color-yellow;
      z-index: -1;
      animation: b-zoom 4s ease-out infinite;
    }
    &:hover {
      color: $color-yellow;
      background: $color-white;
    }
  }
  &.v2 {
    position: relative;
    width: 50px;
    height: 50px;
    font-size: 20px;
    color: $color-green;
    background: $color-white;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      opacity: 0.5;
      background: $color-white;
      z-index: -1;
      animation: b-zoom 4s ease-out infinite;
      @include transform(scale(1.1));
      @include box-shadow(0 0 30px rgba(0, 0, 0, 0.09));
    }
    &:hover {
      color: $color-yellow;
      background: $color-white;
    }
  }
}
