@charset "UTF-8";

/*
Theme Name: Farmus - Agriculture and Organic Farm HTML5 Template
Theme URI: https://themeforest.net/user/techsometimes/portfolio
Design by: techsometimes
Developed by: A N Abdullah Al Numan
Version: 1.0
License: 
Tags: 
*/

/*================================================
[  Table of contents  ]
================================================

    1 Theme Default

    2 Top Bar 

    3 Menu Bar 

    4 Banner

    5 About Us

    6 Services

    7 Projects Gallery

    8 FAQ

    9 Work Process

    10 Counting Items

    11 Our Team

    12 Testimonial
    
    13 Client Logo

    14 Massage Form

    15 Our Blogs
    
    16 Service Details

    17 Projects Details

    18 Contact Us

    19 FAQ Info

    20 Map Info

    21 Blog Post

    22 Blog Details

    23 Info Footer
    
======================================
[ End table content ]
======================================*/

@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

/* =================
    Google Fonts 
===================*/

@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Manrope:wght@400;500;600;700;800&display=swap");

/*=====================
    1 Theme Default
=======================*/

.row,
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.875rem;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.2px;
  word-spacing: 0px;
  word-wrap: break-word;
  background: $color-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black;
}

h1 {
  font-size: 54px;
  line-height: 66px;
}

h2 {
  font-size: 48px;
  line-height: 60px;
}

h3 {
  font-size: 32px;
  line-height: 44px;
}

h4 {
  font-size: 24px;
  line-height: 36px;
}

h5 {
  font-size: 20px;
  line-height: 30px;
}

h6 {
  font-size: 18px;
  line-height: 28px;
}

p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: 26px;
  color: $color-gBlack;
}
.color-green {
  color: $color-green;
}

a,
button,
.btn {
  outline: none;
  cursor: pointer;
  color: $color-black;
  @include transition;
  &:hover {
    color: $color-green;
    outline: none;
  }
}
a {
  text-decoration: none;
  &:hover {
    color: $color-green;
    text-decoration: none;
  }
}

.btn,
.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0em rgba(0, 123, 255, 0.25);
}

input,
.uneditable-input,
select,
textarea {
  @include transition;
}

section {
  padding: 50px 0px;
  &.pt-spach {
    padding-top: 200px;
  }
  &.pb-spach {
    padding-bottom: 200px;
  }
  &.mt-spach {
    margin-top: -100px;
  }
  &.mb-spach {
    margin-bottom: -100px;
  }
}
