@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.star-mark {
  @include d-flex;
  @include align-items;
  gap: 8px;
  .my-icon {
    font-size: 19px;
    color: $color-yellow;
  }
  &.star-1 li:nth-child(1) .my-icon::before,
  &.star-2 li:nth-child(1) .my-icon::before,
  &.star-2 li:nth-child(2) .my-icon::before,
  &.star-3 li:nth-child(1) .my-icon::before,
  &.star-3 li:nth-child(2) .my-icon::before,
  &.star-3 li:nth-child(3) .my-icon::before,
  &.star-4 li:nth-child(1) .my-icon::before,
  &.star-4 li:nth-child(2) .my-icon::before,
  &.star-4 li:nth-child(3) .my-icon::before,
  &.star-4 li:nth-child(4) .my-icon::before,
  &.star-5 li:nth-child(1) .my-icon::before,
  &.star-5 li:nth-child(2) .my-icon::before,
  &.star-5 li:nth-child(3) .my-icon::before,
  &.star-5 li:nth-child(4) .my-icon::before,
  &.star-5 li:nth-child(5) .my-icon::before {
    content: "\e932";
  }
}
