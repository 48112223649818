@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

/*========================
    23 Info Footer
==========================*/
.info-footer {
  &.v1,
  &.v2 {
    padding-top: 50px;
    .footer__widget {
      margin-top: 50px;
      .footer__widget-title ~ .footer__widget-content {
        margin-top: 35px;
      }
      h4,
      h5,
      h6,
      p {
        color: $color-white;
      }
      h4,
      h5 {
        font-weight: 700;
      }
      h4 ~ .subscriber {
        margin-top: 35px;
      }
      .recent-post {
        li {
          @include d-flex;
          @include align-items;
          gap: 15px;
          & ~ li {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 1px solid $color-green;
          }
        }
        .post-img img {
          width: 80px;
          height: 85px;
          border-radius: 5px;
        }
        .post-text {
          width: calc(100% - 80px);
          .post-time {
            @include d-flex;
            @include align-items;
            gap: 7px;
            .my-icon {
              color: $color-green;
            }
            p {
              color: $color-white;
              opacity: 0.8;
            }
            & ~ h6 {
              margin-top: 8px;
            }
          }
          h6 {
            font-weight: 700;
            color: $color-white;
            a {
              color: $color-white;
              &:hover {
                color: $color-green;
              }
            }
          }
        }
        ul ~ .read-more-link {
          margin-top: 30px;
        }
        .read-more-link {
          color: $color-white;
          &:hover {
            color: $color-green;
          }
        }
      }
      .footer__widget-content {
        .my-icon {
          color: $color-green;
        }
        p {
          color: $color-white;
        }
        a {
          color: $color-white;
          font-size: 16px;
          &:hover {
            color: $color-green;
          }
        }
      }
      .our-link,
      .more-servicve {
        li ~ li {
          margin-top: 20px;
        }
      }
      .more-servicve {
        a {
          position: relative;
          padding-left: 30px;
          color: $color-white;
          &::before {
            position: absolute;
            left: 0;
            top: 5px;
            speak: never;
            font-size: 14px;
            color: $color-green;
            content: "\e90b";
            font-family: "icomoon";
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }
      .address-link {
        li {
          @include d-flex;
          & ~ li {
            margin-top: 14px;
          }
          gap: 15px;
          .my-icon {
            margin-top: 7px;
          }
        }
      }
    }
    .row > div:nth-child(1) .footer__widget {
      margin-top: 0;
    }
    .main-footer,
    .main-footer .social-link ul,
    .main-footer .link-group ul {
      @include d-flex;
      @include align-items;
    }
    .main-footer {
      gap: 30px;
      padding: 30px 0px;
      margin-top: 50px;
      border-top: 1px solid $color-gBlack;
      @include justify-content-between;
      @include flex-wrap;
      .social-link ul {
        gap: 30px;
      }
      .social-link a,
      p,
      p a,
      .link-group a {
        color: $color-white;
      }
      .social-link a:hover,
      p a:hover,
      .link-group a:hover {
        color: $color-green;
      }
      .link-group ul {
        gap: 20px;
      }
    }
  }
  &.v1 {
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.9;
      background: $color-black;
      z-index: -1;
    }
    .footer__widget {
      .subscriber {
        form {
          input {
            width: 100%;
            color: $color-white;
            background: $color-tr;
            border: 1px solid $color-green;
            border-radius: 5px;
            & ~ button {
              margin-top: 30px;
            }
          }
          button {
            text-transform: uppercase;
            border-radius: 5px;
          }
        }
        & ~ .address-info {
          margin-top: 50px;
        }
      }
      .address-info {
        @include d-flex;
        @include flex-wrap;
        @include justify-content-between;
        gap: 30px;
        .icon-title {
          @include d-flex;
          @include align-items;
          gap: 10px;
          .my-icon {
            width: 30px;
            height: 30px;
            color: $color-white;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            border-radius: 100px;
            background: $color-green;
          }
          & ~ .text-info {
            margin-top: 15px;
          }
        }
        .text-info li ~ li {
          margin-top: 5px;
        }
        p {
          color: $color-white;
          a {
            color: $color-white;
            &:hover {
              color: $color-green;
            }
          }
        }
      }
    }
  }
  &.v2 {
    .logo-subscriber {
      @include justify-content-between;
      @include flex-wrap;
      gap: 30px;
      border-bottom: 1px solid $color-gBlack;
      padding-bottom: 50px;
      margin-bottom: 50px;
      &,
      .footer-subscrib,
      .icon-text {
        @include d-flex;
        @include align-items;
      }
      .footer-logo {
        max-width: 160px;
        a {
          display: block;
          img {
            width: 100%;
          }
        }
      }
      .footer-subscrib {
        width: 100%;
        @include flex-wrap;
        gap: 40px;
      }
      .icon-text {
        width: 250px;
        font-size: 40px;
        color: $color-white;
        gap: 20px;
        h5 {
          color: $color-white;
          font-weight: 700;
        }
      }
      .sent-email {
        width: 100%;
        input {
          width: 100%;
          height: 50px;
          & ~ button {
            margin-top: 30px;
          }
        }
        button {
          border-radius: 0px;
        }
      }
    }
    .footer__widget {
      .get-free-estimate {
        .my-icon {
          font-size: 40px;
          color: $color-green;
        }
        .phone-text {
          @include d-flex;
          @include align-items;
          gap: 20px;
          .text {
            p {
              font-weight: 700;
            }
            h6 ~ p {
              margin-top: 5px;
            }
          }
          & ~ P {
            margin-top: 10px;
          }
        }
        p ~ a {
          margin-top: 30px;
        }
      }
    }
  }
}
