@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.slider {
  position: relative;
  overflow: hidden;
  padding-bottom: 45px;
  .swiper-pagination {
    bottom: 0px;
    z-index: 1;
    .swiper-pagination-bullet {
      position: relative;
      width: 30px;
      height: 5px;
      border-radius: 2px;
      padding: 0;
      opacity: 1;
      background: $color-gBlack;
      @include transition;
      & ~ .swiper-pagination-bullet {
        margin: 0px 0px 0px 10px;
      }
      &.swiper-pagination-bullet-active {
        background: $color-black;
      }
    }
  }
}
