@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

/*=======================
    16 Service Details
=========================*/

.service-details {
  &.v1 {
    .service-sidebar {
      .service-links-list ~ .need-help {
        margin-top: 60px;
      }
    }
    .service-links-list {
      border-radius: 5px;
      background: $color-white;
      @include box-shadow(0px 4px 24px -6px rgba(19, 16, 34, 0.1));
      h5 {
        padding: 20px;
        font-weight: 700;
        border-radius: 5px 5px 0px 0px;
        background: $color-white3;
      }
      li {
        & ~ li {
          border-top: 1px solid $color-border;
        }
        a {
          position: relative;
          padding: 20px 40px 20px 20px;
          display: block;
          font-weight: 700;
          color: $color-gBlack;
          &::before {
            position: absolute;
            content: "\e902";
            top: 25px;
            right: 20px;
            font-family: "icomoon";
            font-size: calc(100% - 4px);
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          &:hover {
            color: $color-white;
            background: $color-green;
          }
        }
      }
    }
    .need-help {
      position: relative;
      z-index: 1;
      width: 100%;
      min-height: 420px;
      border-radius: 5px;
      @include d-flex;
      @include align-items;
      @include justify-content-center;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.8;
        border-radius: 5px;
        background: $color-black;
        z-index: -1;
      }
      .profile-numbber {
        padding: 30px 18%;
        text-align: center;
        .my-icon {
          width: 55px;
          height: 55px;
          color: $color-green;
          font-size: 24px;
          line-height: 55px;
          border-radius: 100px;
          background: $color-white;
          margin: 0 auto;
          & ~ .text-content {
            margin-top: 15px;
          }
        }
        .text-content {
          h6,
          h4,
          a,
          p {
            color: $color-white;
          }
          a:hover {
            color: $color-green;
          }
          h4 {
            font-weight: 700;
          }
          h4 ~ p {
            margin-top: 20px;
          }
        }
      }
    }
    .service-content {
      margin-top: 40px;
      h2,
      h4,
      h5 {
        font-weight: 700;
      }
      h2 ~ p {
        margin-top: 20px;
      }
      p ~ .serv-box-img-text,
      .serv-box-img-text ~ .our-features,
      .our-features ~ .key-service,
      .key-service ~ .para-text {
        margin-top: 40px;
      }
    }
    .serv-box-img-text {
      @include d-flex;
      @include align-items;
      @include flex-wrap;
      gap: 30px;
      .box-img img {
        width: 100%;
      }
      .box-text {
        h5 ~ ul {
          margin-top: 20px;
        }
      }
    }
    .our-features {
      h4 ~ ul {
        margin-top: 20px;
      }
      ul,
      li {
        @include d-flex;
      }
      ul {
        @include flex-wrap;
        @include justify-content-between;
        gap: 30px;
      }
      li {
        gap: 20px;
        .my-icon {
          font-size: 40px;
          color: $color-green;
        }
        .text-content h5 ~ p {
          margin-top: 16px;
        }
      }
    }
    .key-service h4 ~ ul {
      margin-top: 30px;
    }
    .para-text {
      h2 ~ p {
        margin-top: 20px;
      }
      p ~ P {
        margin-top: 20px;
      }
      & ~ .faq-accordion {
        margin-top: 30px;
      }
    }
    .faq-accordion {
      li {
        padding: 20px 30px;
        border: 1px solid $color-border;
        border-radius: 5px;
        & ~ li {
          margin-top: 30px;
        }
        button {
          @include d-flex;
          @include align-items-start;
          @include justify-content-between;
          gap: 30px;
          width: 100%;
          text-align: inherit;
          padding: 0px;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: $color-green;
          background: $color-tr;
          @include box-shadow(0px 4px 243px -6px rgba(19, 16, 34, 0.1));
          span {
            width: 30px;
            min-width: 30px;
            height: 30px;
            text-align: center;
            line-height: 32px;
            border-radius: 50px;
            color: $color-white;
            background: $color-green;
            &::before {
              font-family: "icomoon";
              content: "\e929";
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
          &.collapsed {
            color: $color-black;
            span::before {
              content: "\e92f";
            }
          }
        }
        .faq-content-body {
          padding-top: 20px;
        }
      }
    }
  }
}
