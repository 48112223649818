@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.message-form {
  &.v1,
  &.v2,
  &.v3 {
    .group-box {
      @include d-flex;
      @include align-items;
      @include flex-wrap;
      gap: 30px;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    input[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type="number"] {
      user-select: none;
    }
    input,
    textarea,
    .my-select {
      width: 100%;
      border: 1px solid $color-border;
      border-radius: 5px;
      background: $color-white;
    }
    input,
    .my-select {
      height: 70px;
    }
    input,
    textarea {
      color: $color-gBlack;
      font-size: 16px;
      line-height: 26px;
    }
    textarea {
      height: 140px;
      padding: 15px 20px;
    }
    .my-select {
      width: calc(100% + 50px);
      text-align: left;
      button {
        border: none;
        width: 100%;
        height: 100%;
        text-align: left;
      }
    }
    .submit-btn {
      padding: 20px 30px;
    }
    .group-box ~ .group-box,
    .group-box ~ textarea,
    textarea ~ .btn-anime {
      margin-top: 30px;
    }
  }
  &.v1 {
    .submit-btn {
      display: block;
      width: 100%;
    }
  }
  &.v3 {
    text-align: center;
  }
}
