@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  @include d-flex;
  @include align-items;
  @include justify-content-center;
  background: $color-black;
  .corners {
    position: relative;
    width: 60px;
    height: 60px;
    @include transform-origin(center);
    animation: spin 3s infinite linear;
    li {
      position: absolute;
      width: 100%;
      height: 100%;
      &::before {
        display: block;
        content: "";
        width: 48%;
        height: 48%;
        border-radius: 0 40% 0 40%;
        background: $color-green;
      }
      &:nth-child(1) {
        animation: spin1 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
      &:nth-child(2) {
        animation: spin2 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
      &:nth-child(3) {
        animation: spin3 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
      &:nth-child(4) {
        animation: spin4 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin1 {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin2 {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(270deg);
    }
    70% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin3 {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(180deg);
    }
    70% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin4 {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(90deg);
    }
    70% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
