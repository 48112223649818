@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

/*=====================
    21 Blog Post
=======================*/

.blog-post {
  &.v1 {
    .blog-post-card {
      border-radius: 10px;
      @include box-shadow(0px 4px 24px -6px rgba(19, 16, 34, 0.1));
      &.video-box .post-img {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0.3;
          background: $color-black;
          z-index: 1;
        }
      }
      &.post-slider {
        position: relative;
        overflow: hidden;
        .slider {
          padding-bottom: 0;
          .post-img {
            border-radius: 0;
          }
        }
        .prev-btn,
        .next-btn {
          position: absolute;
          width: 50px;
          height: 50px;
          font-size: 18px;
          color: $color-white;
          top: 50%;
          @include transform(translateY(-50%));
          line-height: 45px;
          background: $color-green;
          z-index: 1;
        }
        .prev-btn {
          left: 0;
          border-radius: 0px 5px 5px 0px;
        }
        .next-btn {
          right: 0;
          border-radius: 5px 0px 0px 5px;
        }
      }
      .post-img {
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        img {
          width: 100%;
          @include object-fit;
          @include object-position;
          @include transition;
        }
        .play-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          z-index: 1;
        }
      }
      .post-body {
        position: relative;
        padding: 30px 20px 0px 30px;
        .day-box {
          font-size: 18px;
          font-weight: 700;
          color: $color-green;
          & ~ .post-title {
            margin-top: 15px;
          }
        }
        .post-title {
          position: relative;
          font-weight: 700;
          padding-bottom: 30px;
          &::before,
          &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            height: 5px;
          }
          &::before {
            width: 100%;
            background: $color-border;
          }
          &::after {
            width: 20%;
            background: $color-green;
          }
        }
        p {
          padding: 35px 0px;
          border-bottom: 1px solid $color-border;
        }
      }
      .post-footer,
      .post-footer ul,
      .post-footer li {
        @include d-flex;
        @include align-items;
      }
      .post-footer {
        padding: 30px;
        gap: 30px;
        @include flex-wrap;
        ul {
          @include flex-wrap;
          gap: 10px;
          li {
            gap: 5px;
            & ~ li {
              padding-left: 10px;
              border-left: 1px solid $color-border;
            }
          }
        }
        .my-icon {
          color: $color-green;
        }
        p a {
          color: $color-gBlack;
          &:hover {
            color: $color-green;
          }
        }
      }
      &:hover img {
        @include transform(rotate(1.05deg) scale(1.1));
      }
      & ~ .blog-post-card,
      & ~ .pegination {
        margin-top: 60px;
      }
    }
    .pegination {
      ul {
        @include d-flex;
        @include flex-wrap;
        @include align-items;
        @include justify-content-center;
        gap: 10px;
      }
      li a,
      li .page-numbers {
        display: inline-block;
        padding: 8px 15px;
        font-size: 16px;
        font-weight: 500;
        color: $color-black;
        border: 1px solid $color-border;
        border-radius: 5px;
        &:hover {
          color: $color-white;
          background: $color-green;
        }
      }
      li .page-numbers {
        @include transition;
      }
      li.active a,
      li .current {
        color: $color-white;
        background: $color-green;
      }
    }
  }
}
