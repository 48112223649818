@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

.scroll-bottom-Top {
  position: fixed;
  width: 55px;
  height: 55px;
  right: 0px;
  bottom: 50px;
  text-align: center;
  line-height: 55px;
  opacity: 0;
  font-size: 15px;
  visibility: hidden;
  border-radius: 100px;
  color: $color-green;
  background: $color-white;
  box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1),
    0px 4px 13px -2px rgba(19, 16, 34, 0.06);
  z-index: 5;
  &.show {
    right: 50px;
    opacity: 1;
    visibility: visible;
  }
  .my-icon {
    display: inline-block;
    transform: rotate(-180deg);
  }
}
