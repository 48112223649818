@use "../abstracts/variables" as *;
@use "../abstracts/autoprefixer" as *;

/*========================
    17 Projects Details
==========================*/

.projects-details {
  &.v1 {
    .projects-social {
      @include d-flex;
      @include align-items;
      @include flex-wrap;
      gap: 25px;
      a {
        display: inline-block;
        width: 45px;
        height: 45px;
        font-size: 20px;
        color: $color-green;
        text-align: center;
        line-height: 45px;
        border-radius: 50px;
        background: $color-white2;
        &:hover {
          color: $color-white;
          background: $color-green;
        }
      }
    }
    .section-title ~ .main-content,
    .section-title-center ~ .main-content {
      margin-top: 50px;
    }
    .main-content {
      .projects-info {
        img {
          width: 100%;
          border-radius: 10px;
        }
        .info-list {
          position: relative;
          padding: 35px 0px;
          margin: 40px 0px;
          @include d-flex;
          @include align-items;
          @include flex-wrap;
          background: $color-white;
          border-radius: 10px;
          @include box-shadow(0px 4px 24px rgba(19, 16, 34, 0.1));
          z-index: 1;
          li {
            padding-left: 40px;
            width: 100%;
            p ~ h5 {
              margin-top: 10px;
            }
            h5 {
              font-weight: 700;
            }
          }
        }
        & ~ .row {
          margin-top: 60px;
        }
      }
      p ~ h6,
      h6 ~ p,
      p ~ ul {
        margin-top: 30px;
      }
      h4,
      h5,
      h6 {
        font-weight: 700;
      }
      .prev-next-btns {
        @include d-flex;
        @include align-items;
        @include flex-wrap;
        @include justify-content-between;
        gap: 20px;
        padding: 30px 0px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        margin-top: 60px;
        a {
          font-size: 18px;
          font-weight: 700;
          .my-icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            color: $color-green;
            border-radius: 100px;
            background: $color-white2;
          }
          .my-icon ~ .text,
          .text ~ .my-icon {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

/*=====================
    18 Contact Us
=======================*/

.contact-us {
  &.v1,
  &.v2,
  &.v3 {
    background: $color-white2;
    form {
      .response.error,
      .response.success {
        padding: 20px;
        border-radius: 5px;
        background: $color-white;
      }
      .response.error {
        color: #ef4444;
      }
      .response.success {
        color: #22c55e;
      }
    }
  }
  &.v1 {
    .contact-info {
      margin-top: 50px;
      h4 {
        font-weight: 500;
        & ~ .contact-list {
          margin-top: 25px;
        }
      }
      .contact-list {
        @include flex-wrap;
        &,
        li {
          @include d-flex;
          @include align-items;
          gap: 30px;
        }
        .my-icon {
          font-size: 30px;
          color: $color-green;
        }
        .text {
          h5 {
            font-weight: 700;
            & ~ p {
              margin-top: 3px;
            }
          }
        }
        & ~ .contact-map {
          margin-top: 40px;
        }
      }
      .contact-map iframe {
        width: 100%;
        min-height: 210px;
        border-radius: 10px;
      }
    }
  }
  &.v2 {
    .contact-info {
      .section-title ~ .need-help {
        margin-top: 20px;
      }
      .need-help {
        @include d-flex;
        gap: 15px;
        .my-icon {
          position: relative;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          color: $color-green;
          z-index: 1;
          margin-top: 6px;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.1;
            border-radius: 100px;
            background: $color-green;
            z-index: -1;
          }
        }
        .text {
          h6 {
            font-size: 16px;
            font-weight: 500;
            color: $color-gBlack;
          }
          h5 {
            font-weight: 700;
            & ~ p {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  &.v3 {
    .section-title-center ~ .message-form.v3,
    .section-title ~ .message-form.v3 {
      margin-top: 50px;
    }
  }
}
